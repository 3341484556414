body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  height: 100vh !important;
}
/* Hide the spinners in WebKit browsers */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.primary_background{
  background: #2C9CF0 !important;
}
.secondary_background{
  color: #fff;
  background: #6A6A6A !important;
}

.secondary_background:hover{
  color: #fff;
  background: #6A6A6A !important;
}

.fontstylePoppin {
  font-family: "Poppins";
}

code {
  font-family: "Poppins", sans-serif;
}

/* Common Css */
.link {
  color: white;
  text-decoration: none;
}

.main-PageHeading{
  font-family: "Poppins";
  font-size: 22px;
}
.mainSub-PageHeading{
  font-family: "Poppins";
  font-size: 20px;
}

@media screen and (max-width :578px) {
  #hideforSmallDevices {
    display: none !important;
  }

  .main-PageHeading{
    font-family: "Poppins";
    font-size: 20px;
  }
  .mainSub-PageHeading{
    font-family: "Poppins";
    font-size: 18px;
  }

  .smallscreen{
    display: none;
  }
}

/* AddEventDetails Css */
.addeventdetailDiv {
  width: 80%;
  margin: auto;
  margin-bottom: 10%;
}

.subnavbar {
  width: 70%;
  margin: auto;
  margin-top: 32px;
}

.subnavitem {
  font-family: "Poppins";
  font-size: 23px;
  color: #808080;
}

.isActiveForm {
  color: rgb(28, 184, 246);
}

.defalut {
  color: #808080;
}

.poppins20 {
  font-family: "Poppins";
  font-size: 18px;
}

.eveVisibilitybtn {
  width: 20% !important;
  height: 47px;
}

.adminfooterDiv {
  height: 80px !important;
  width: 100%;
  background: #ffff !important;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: end;
  align-items: center;
}

.nextbtn {
  background: #2C9CF0 !important;
  width: 124px !important;
  font-size: 20px !important;
  font-family: "Poppins";
  height: 45px !important;
  color: #ffff !important;
  margin-right: 19% !important;
}
.basicnextbtn{
  background: #2C9CF0 !important;
  width: 124px !important;
  font-size: 20px !important;
  font-family: "Poppins";
  height: 45px !important;
  color: #ffff !important;
  margin-right: 11.5% !important;
}
.locationnextbtn{
  background: #2C9CF0 !important;
  width: 124px !important;
  font-size: 20px !important;
  font-family: "Poppins";
  height: 45px !important;
  color: #ffff !important;
  margin-right: 11.5% !important;
}

.backbtn {
  background: #6A6A6A !important;
  width: 124px !important;
  height: 45px !important;
  color: #ffff !important;
  margin-right: 10px !important;
}

.backbtn:hover {
  background: #259bf4 !important;
}

.backbtn:hover~.nextbtn {
  background: #6A6A6A !important;
}

.coverImg_style {
  background-color: rgb(244, 247, 248);
  border: 2px dashed rgb(188, 199, 203);
  width: 388px;
  height: 202px;
}

.uploadFile {
  position: absolute;
  width: 388px;
  height: 202px;
}

.selectFileStyle {
  position: relative;
  top: 0;
  Z-index: 1;
  opacity: 0;
}

.poppins16 {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: bold;
}

.poppins12 {
  font-family: "Poppins";
  font-size: 12px;
  color: #676767;
}

.uploadIcon {
  font-size: 50px !important;
  margin-top: 10%;
}

.uploadbtn {
  width: 388px !important;
  background: #2C9CF0 !important;
  color: #ffff !important;
}

.tickettypebtn {
  width: 45% !important;
  height: 47px;
  margin-left: -1% !important;
  border: 2px solid #2C9CF0 !important;
}

.primary {
  background: #2C9CF0 !important;
}

@media screen and (max-width: 1115px) {
  .subnavbar {
    width: 60%;
    margin: auto;
    margin-top: 32px;
  }

  .subnavitem {
    font-family: "Poppins";
    font-size: 20px;
    color: #808080;
  }

  .poppins20 {
    font-family: "Poppins";
    font-size: 18px;
  }

  .coverImg_style {
    background-color: rgb(244, 247, 248);
    border: 2px dashed rgb(188, 199, 203);
    width: 368px;
    height: 202px;

  }

  .uploadFile {
    position: absolute;
    width: 368px;
    height: 202px;

  }

  .uploadbtn {
    width: 368px !important;
    background: #2C9CF0 !important;
    color: #ffff !important;
  }
}

@media screen and (max-width: 768px) {
  .subnavbar {
    width: 60%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: -5%;
  }

  .subnavitem {
    font-family: "Poppins";
    font-size: 15px;
    color: #808080;
  }

  .poppins20 {
    font-family: "Poppins";
    font-size: 12px;
  }

  .adminfooterDiv {
    height: 45px !important;
    background: #D9D9D9 !important;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .nextbtn {
    background: #6A6A6A !important;
    width: 104px !important;
    height: 35px !important;
    color: #ffff !important;
    margin-right: 20% !important;
  }

  .backbtn {
    background: #6A6A6A !important;
    width: 104px !important;
    height: 35px !important;
    color: #ffff !important;
    margin-right: 10px !important;
  }

  .eveVisibilitybtn {
    width: 25% !important;
    height: 37px;
  }

  .form-control {
    font-size: 12px !important;
  }

  .coverImg_style {
    background-color: rgb(244, 247, 248);
    border: 2px dashed rgb(188, 199, 203);
    width: 308px;
    height: 195px;

  }

  .uploadFile {
    position: absolute;
    width: 308px;
    height: 195px;

  }

  .uploadbtn {
    width: 308px !important;
    background: #2C9CF0 !important;
    color: #ffff !important;
  }
}

@media screen and (max-width: 545px) {
  .addeventdetailDiv {
    width: 90%;
    margin: auto;
  }

  .subnavbar {
    width: 80%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: -5%;
  }

  .subnavitem {
    font-family: "Poppins";
    font-size: 12px;
    color: #808080;
  }

  .poppins20 {
    font-family: "Poppins";
    font-size: 10px;
  }

  .adminfooterDiv {
    height: 45px !important;
    background: #D9D9D9 !important;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .nextbtn {
    background: #6A6A6A !important;
    width: 104px !important;
    height: 35px !important;
    color: #ffff !important;
    margin-right: 20% !important;
  }

  .eveVisibilitybtn {
    width: 40% !important;
    height: 37px;
  }

  .form-control {
    font-size: 10px !important;
    border-color: #2C9CF0 !important;
  }

  .coverImg_style {
    background-color: rgb(244, 247, 248);
    border: 2px dashed rgb(188, 199, 203);
    width: 255px;
    height: 195px;

  }

  .uploadFile {
    position: absolute;
    width: 255px;
    height: 195px;

  }

  .uploadbtn {
    width: 220px !important;
    background: #2C9CF0 !important;
    color: #ffff !important;
  }

  .poppins16 {
    font-size: 12px;
  }

  .poppins12 {
    font-size: 10px;
  }

  .tickettypebtn {
    width: 20% !important;
    height: 32px;
    font-size: 10px !important;
  }
}




/* Css For Admin Part */

/* Style For Admin Part */
/* Admin EventList Page CSS */
.eventHeader {
  background-color: rgba(87, 36, 195, 0.877);
}

.viewbtn {
  border: 1px solid gray;
  border-radius: 20px;
  background-color: rgb(254, 252, 252);
  width: 80px;
}

.eventList {
  width: 98%;
}

.eventList:hover {
  width: 100%;
  box-shadow: 2px 5px 10px rgb(185, 183, 183);
}


.isActiveForm {
  color: rgb(28, 184, 246);
}

.border-line {
  border-top: 2px solid gray;
  margin-top: 12px;
}

.form-control {
  border: 2px solid rgb(28, 184, 246);
}

.activebtn {
  width: 70% !important;
}
.ticketactivebtn{
  width: 80% !important;
  background: #D5FACF;
  color: #30912E;
  padding: 0.2rem 0.7rem;
}

.addticketbtn {
  border: 1.5px solid #2C9CF0 !important;
  color: #2C9CF0 !important;
}

.file-input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.file-input-container input[type="file"] {
  display: none;
}

.file-input-container label.file-input-label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  padding: 0 10px;
  box-sizing: border-box;
}

.file-input-container input[type="text"] {
  width: calc(100% - 40px);
  border: none;
  padding: 0;
  margin: 0;
}

.upload-icon {
  font-size: 20px;
  margin-left: auto;
  margin-right: 10px;
}

.upload-text {
  flex-grow: 1;
}

.file-input-container label.file-input-label:hover {
  border-color: #2C9CF0;
}

.file-input-container label.file-input-label:focus-within {
  border-color: #2C9CF0;
}
