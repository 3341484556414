.ticket {
    height: 302px;
    margin-left: 3% !important;
    margin-top: 40px !important;
}

.height {
    height: 305px;
}

.ticket-img {
    height: 302px;
    position: relative;
}

.overlayOnImg {
    height: 303px;
    position: relative;
    top: -99%;
    border-radius: 2rem;
    display: flex;
}

.overlayeventImg {
    width: 22%;
    height: 201.26px;
    position: relative;
    top: 11%;
    left: 2.9%
}

.overlayeventDetail {
    width: 48.5%;
    height: 201.26px;
    position: relative;
    top: 11%;
    left: 4.4%
}

.overlayticketScanner {
    width: 15%;
    height: 105.8px;
    position: relative;
    top: 28%;
    left: 10%
}

.DMSans22 {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 22px;
}
.back-home{
    text-decoration: none;
    color: black;
}
.ticketName {
    font-family: "Poppins";
    font-size: 10px;
    width: 99%;
    margin: auto;
    padding: 1px 8px;
    background: #F0F1F2;
    border-radius: 1rem;
}

.poppins15 {
    font-family: "Poppins";
    font-size: 15px;
    color: #6A6A6A;
}

.poppins11 {
    font-family: "Poppins";
    font-size: 11px;
}

.ticketStatus {
    background: #00AC07;
    max-width: 214px;
    min-width: 65%;
    height: 30px;
    font-size: 20px;
    padding: 1rem 0rem;
    font-family: "Poppins";
    color: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
}

#smallScreenView {
    display: none;
}

.ticketTempletImageDiv{
    height: 45vh;
}
.ticketTempletImage{
    height: 100%;
    width: 100%;
    margin: auto;
}

.ticketTempletDetails{
    position: relative;
    width: 90%;
    margin: auto;
    margin-top : -105% !important;
    padding: 0px 0px !important;
    z-index: 1;
    
}

.ticketTempletDetailData{
    font-size: 12px;
    font-family: "Poppins";
}
@media screen and (max-width:830px) {
    #smallScreenView {
        display: block;
    }

    #LargeScreenView {
        display: none;
    }

    .poppins14 {
        font-size: 14px;
        font-family: "Poppins";
        color: #6A6A6A;
    }

    .ticketStatus {
        background: #00AC07;
        min-width: 92px;
        max-width: 40%;
        height: 17.57px !important;
        font-size: 12px;
        font-family: "Poppins";
        color: #ffff;
        border-radius: 0.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0rem;
    }

    .DMSans22 {
        font-family: "Poppins";
        font-size: 16px;
    }

    .ticketName {
        font-family: "Poppins";
        font-size: 12px;
        width: 100%;
        padding: 1px 8px;
        background: #F0F1F2;
        border-radius: 1rem;
    }

    .poppins15 {
        font-family: "Poppins";
        font-size: 12px;
        color: #6A6A6A;
    }

}
@media screen and (max-width: 380px) {
    .ticketTempletImageDiv{
        height: 55vh;
    }
    .ticketTempletImage{
        height: 100%;
        width: 100%;
        margin: auto;
    }
    
    .ticketTempletDetails{
        position: relative;
        width: 90%;
        margin: auto;
        margin-top : -108%;
        padding: 0px 0px !important;
        z-index: 1;
        
    }
    
    .ticketTempletDetailData{
        font-size: 12px;
        font-family: "Poppins";
    }
}
