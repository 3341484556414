.sub-head{
    font-size: 26px !important;
    font-family: "Poppins";
    color: #587EF9;
}
.set-padding{
    padding-top: 2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 194px;
}
.screenshort{
    height: 162px;
}
.step1{
    color: #587EF9;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 200px;
}
.step2{
    color: #F41E6D;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 200px;
}
.step3{
    color: #FDECA0;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 200px;
}
.steps-conten{
    font-family: "Poppins";
    font-size: 18px;
}
.content-body{
    width: 70%;
    margin: auto;
}
.create-event-btn{
    background-color: #F41E6D;
    width: 100%;
    font-size: 20px !important;
    padding: 0.5rem 1rem !important;
    border: none !important;
    color: #FFFF;
}
.sub-head2{
    font-size: 22px !important;
    font-family: "Poppins";
    color: #F41E6D;
    font-weight: 400;
}
.shadow-style{
    box-shadow: 5px 5px 10px 0.5px #f0eded;
}
#displayForSmallDevice{
    display: none;
}

@media screen and (max-width: 568px) {
    #displayForLargeDevice{
        display: none;
    }
    #displayForSmallDevice{
        display: block;
    }
    .content-body{
        width: 100%;
        margin: 0;
    }
    .set-padding{
        padding: 2.5rem;
        height: 194px;
    }
    .shadow-style{
        box-shadow: 5px 5px 10px 0.5px #f0eded;
        margin-bottom: 2rem;
    }
    .create-event-btn{
        background-color: #F41E6D;
        width: 95%;
        margin: auto;
        font-size: 20px !important;
        padding: 0.5rem 1rem !important;
        border: none !important;
        color: #FFFF;
    }
}