.avaibilityShows{
    color : green
}
.no-avaibilityShows{
    color: red;
}
.dateSpanStyle{
    border: 1px solid #2C9CF0;
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 1%;
    cursor: pointer;
}
.onSelect{
    border: 1px solid #2C9CF0;
    border-radius: 0.1rem;
    color: white;
    background: rgb(235, 253, 235);
}

@media screen and (max-width: 580px) {
    .head{
        text-align: center;
    }
    .dateSpanStyle{
        width: 98%;
        border: 1px solid #2C9CF0;
        border-radius: 0.5rem;
        padding: 0.5rem;
        margin: 0% 1%;
        cursor: pointer;
        text-align: center;
    }
    .sm-row{
        width: 99%;
        padding: 0% 1%;
        display: flex;
        justify-content: center;
        /* border: 2px solid red; */
        margin: auto !important;
    }
    .sm-custom{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .ticket-status{
        margin-top: -1.5rem;
    }
}