.footerDiv {
  min-height: 275px;
  background: #04092C;
  margin-top: 55px;
}

.footerLogo {
  display: flex;
  align-items: center;
}

.footerlogoImg {
  width: 221px;
  height: 60px;
  margin-left: 72px;
  margin-top: 30%;
}

.footerCols, .footerColsm {
  display: flex;
  justify-content: center;
}

.footerList {
  list-style: none;
  margin-top: 40%;
}

.footerHeader {
  color: #2C9CF0;
  font-family: Poppins;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.footerContents, .footerContentsm{
  color: #A5A0A0;
  font-size: small;
  font-family: Poppins;
  margin-bottom: 8px;
}
.link{
  color: #A5A0A0;
}

.footer-footer {
  color: #F2F6F9;
  font-family: Poppins;
  font-size: small;
}

.smallscreeview {
  display: none;
}

@media screen and (max-width: 1280px) {
  .footerlogoImg {
    width: 180px;
    height: 60px;
    margin-left: 30px;
    margin-top: 30%;
  }
}

@media screen and (max-width: 986px) {
  .footerDiv{
    padding-top: 5%;
  }
  .footerLogo {
    display: none;
  }

  .footerList {
    list-style: none;
    margin-top: 0%;
  }

  .footerCols {
    display: flex;
    justify-content: start;
  }
  .footerColsm{
    justify-content: center !important;
  }
  .footerContentsm{
    display: inline-flex;
    margin-left: -60px;
  }
  .facebook{
    margin-left: 20px;
  }
}