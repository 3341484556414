.profileheading{
    font-family: "Poppins" !important;
    font-size: 25px !important;
    color: #2C9CF0;
}
.createeventbtn{
    padding: 0.3rem 1rem;
    font-family: "Poppins";
    font-size: 12px;
    background: #2C9CF0;
    color: #ffff;
    text-decoration: none;
    border-radius: 10rem; 
}
.listheading{
    font-family: "Poppins";
    font-size: 20px;
}
.poppin18{
    font-family: "Poppins";
    font-size: 15px;
}
.poppin14{
    font-family: "Poppins";
    font-size: 12px;
    color: #6A6A6A;
}
.lists{
    margin: 0rem 1rem;
}

@media screen and (max-width:580px){
    .hideinSmall, #hideinSmall{
        display: none;
    }
    .username{
        font-size: 12px;
    }
}