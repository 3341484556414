.categorylistDiv {
  height: 245px;
  width: 88%;
  margin: auto;
  margin-top: 52px;
}
.horizontalLine {
  width: 97%;
}
#forsmallScreen {
  display: none;
}
.browseHeading {
  font-family: Poppins;
}
.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-left: -12px;
}
.carousel-wrapper {
  display: flex;
  overflow: hidden;
  width: 100%;
  margin: auto;
}
.carousel-content {
  display: flex;
  width: 100%;
  overflow: hidden;
  transition: transform 1.5s ease-in-out;
}
.carousel-slide {
  display: flex;
  width: 100%;
}

.categorycard {
  width: calc(25% - 20px);
  height: 170px !important;
  margin-left: 25px;
  border: none !important;
}
.categoryblock {
  padding: 0.3rem 0rem;
  border-radius: 1.5rem;
  background-color: rgba(4, 9, 44, 1);
  color: white;
  font-family: Poppins;
  width: 179px;
  height: 38px;
  font-weight: bold;
  position: relative;
  top: -9%;
  cursor: pointer;
  /* border-bottom: 4px solid white;
  border-left: 4px solid white;
  border-right: 4px solid white; */
}
.card-img {
  height: 150px;
  border-radius: 1rem;
  position: relative;
  top : 1%;
  cursor: pointer;
}
.category-img {
  width: 100%;
  height: 150px;
  border-radius: 1rem;
  z-index: -1;
}

.carousel-control {
  width: 30px;
  background: none;
  color: black;
  border: none;
  cursor: pointer;
}
.carousel-control.prev {
  margin-left: -12px;
}
.carousel-control.next {
  margin-left: 17px;
}
.carousel-control.disabled {
  visibility: hidden;
}

@media screen and (max-width:1310px) {
  .categorycard {
    width: calc(25% - 20px);
    height: 160px !important;
    margin-left: 40px;
    border: none !important;
  }
  .categoryblock {
    padding: 0.3rem 0rem;
    border-radius: 1.5rem;
    background-color: #04092C;
    color: white;
    font-family: Poppins;
    width: 150px;
    height: 35px;
    font-weight: bold;
    z-index: 1;
    border-bottom: 4px solid white;
    border-left: 4px solid white;
    border-right: 4px solid white;
  }
  .card-img {
    height: 140px;
    border-radius: 1rem;
    position: relative;
    top: 3%;
  }
  .category-img{
    width: 100%;
    height: 140px;
    border-radius: 1rem;
  }
}
@media screen and (max-width:1080px){
  .categorycard {
    width: calc(25% - 20px);
    height: 145px !important;
    margin-left: 30px;
    border: none !important;
  }
  .categoryblock {
    padding: 0.3rem 0rem;
    border-radius: 1.5rem;
    background-color: #04092C;
    color: white;
    font-family: Poppins;
    width: 135px;
    height: 35px;
    font-weight: bold;
    z-index: 1;
    border-bottom: 3px solid white;
    border-left: 3px solid white;
    border-right: 3px solid white;
  }
  .card-img {
    height: 130px;
    border-radius: 1rem;
    position: relative;
    top: 3%;
  }
  .category-img{
    width: 100%;
    height: 130px;
    border-radius: 1rem;
  }
}
@media screen and (max-width:915px){
  .categorylistDiv {
    height: 185px;
    width: 88%;
    margin: auto;
    margin-top: 52px;

  }
  .categorycard {
    width: calc(25% - 20px);
    height: 110px !important;
    margin-left: 30px;
    border: none !important;
  }
  .categoryblock {
    padding: 0.1rem 0rem;
    border-radius: 1.5rem;
    background-color: #04092C;
    color: white;
    font-family: Poppins;
    width: 135px;
    height: 35px;
    font-weight: bold;
    font-size: medium;
    z-index: 1;
    border-bottom: 3px solid white;
    border-left: 3px solid white;
    border-right: 3px solid white;
  }
  .card-img {
    height: 100px;
    border-radius: 1rem;
    position: relative;
    top: -3%;
  }
  .category-img{
    width: 100%;
    height: 90px;
    border-radius: 1rem;
  }
}
@media screen and (max-width:880px){
  .categorylistDiv {
    height: 185px;
    width: 90%;
    margin: auto;
    margin-top: 52px;

  }
  .categorycard {
    width: calc(35% - 30px);
    height: 110px !important;
    margin-left: 25px;
    border: none !important;
  }
  .categoryblock {
    padding: 0.1rem 0rem;
    border-radius: 1.5rem;
    background-color: #04092C;
    color: white;
    font-family: Poppins;
    width: 65%;
    height: 35px;
    font-weight: bold;
    font-size: small;
    z-index: 1;
    border-bottom: 3px solid white;
    border-left: 3px solid white;
    border-right: 3px solid white;
  }
  .card-img {
    height: 100px;
    border-radius: 1rem;
    position: relative;
    top: 3%;
  }
  .category-img{
    width: 100%;
    height: 90px;
    border-radius: 1rem;
  }
}
@media screen and (max-width:560px){
  .categorylistDiv {
    height: 160.26px;
    width: 90% !important; 
    margin: auto;
    margin-left: 7% !important;
    margin-top: 25px;
  }
  .carousel-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-left: 0px;
  }
  .browseCarousel{
    margin-top: -10px;
  }

  .categorycard {
    width: calc(55% - 10px);
    height: 97px !important;
    margin-left: 10px;
    border: none !important;
  }
  .browseHeading {
    font-family: Poppins;
    margin-bottom: 1.3rem;
  }
  #forsmallScreen {
    display: block;
  }
  #forlargeScreen {
    display: none;
  }
  .categoryblock{
    position: relative;
    top: 2%;
    padding: 0.1rem 0.5rem !important;
    border-radius: 1rem;
    background-color: #04092C;
    color: white;
    font-family: Poppins;
    width: 80%;
    height: 21px;
    font-weight:bold;
    font-size: 12px;
    z-index: 1;
    border-bottom: 2px solid white;
    border-left: 2px solid white;
    border-right: 2px solid white;
  }
 
  .card-img {
    height: 75.35px !important;
    border-radius: 1rem;
    position: relative;
    top: 5%;
  }
  .category-img {
    width: 100%;
    height: 75.35px;
    border-radius: 1rem;
  }
  .carousel-control {
    background: none;
    color: black;
    border: none;
    cursor: pointer;
    margin-top: 5%;
  }
  .carousel-control.next {
    margin-left: 1%;
  }
  .horizontalLine {
    width: 99%;
  }
}