.aboutHead {
    color: #FFE600;
    background: #04092C;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutHeading {
    font-size: 76px;
    font-family: "Poppins";
    font-weight: bold !important;
}

.aboutSubHeading {
    font-size: 22px;
    font-family: "Poppins";
}

.headingStyle {
    font-size: 30px;
    font-family: "Poppins";
    font-weight: 400;
}

.paraStyle {
    font-size: 20px;
    font-family: "Poppins";
    margin-bottom: 77px;
}

.about_img {
    width: 200px;
}

@media screen and (max-width:580px) {
    .about_img {
        width: 60px;
    }

    .aboutHeading {
        font-size: 36px;
        font-family: "Poppins";
        font-weight: bold !important;
    }

    .aboutSubHeading {
        font-size: 18px;
        font-family: "Poppins";
    }

    .headingStyle {
        font-size: 22px;
        font-family: "Poppins";
        font-weight: 400;
    }

    .paraStyle {
        font-size: 16px;
        font-family: "Poppins";
        margin-bottom: 77px;
    }

    .privacypolicy-width {
        width: 100% !important;
    }
}

.privacypolicy-width {
    width: 50%;
}