#smallScreenViewPort {
    display: none;
}

.bookingticketmainDiv {
    margin-top: 23px;
}

.bookingticket {
    width: 60%;
    margin: auto;
    margin-bottom: 184px !important;
}

.ticketHeading {
    display: flex;
    justify-content: center;
    margin-bottom: 51px;
}
.booking-img{
    object-fit: cover;
}

.backarrow {
    position: relative;
    right: 20%;
}

.headingdiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.heading {
    font-family: "Poppins";
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}

.subheading {
    font-family: "Poppins";
    font-size: 15px;
    text-align: center;
}

.bookinghead {
    font-family: "Poppins";
    font-size: 25px;
    font-weight: bold;
}

.eventimg {
    width: 357px;
    height: 168px;
}

.ticketname {
    font-family: "Poppins";
    font-size: 15px;
    font-weight: bold;
}

.quantity {
    font-family: "Poppins";
    font-size: 15px;
}

.bookingbifargation {
    width: 365px;
    min-width: 196px;
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
    border: 1px solid #a0a0a0;

}
.summaryValue{
    font-family: "Poppins";
    font-size: 14px;
}
.summarybiValue{
    font-family: "Poppins";
    font-size: 13px;
}
.discount {
    width: 269.48px;
    height: 31.4;
    margin-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.have-discount {
    height: 27px;
    font-size: 14px;
    margin-right: 10px;
}

.code {
    width: 91.58px;
    height: 31.4px;
    font-size: 12px;
    border: 0.8px solid #E0E0E0;
    padding-left: 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

}

.apply {
    width: 112px;
    height: 31.4px;
    font-size: 14px;
    background-color:  rgba(44, 156, 240, 0.2);
    text-align: center;
    border: 1px solid rgba(44, 156, 240, 1);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    align-items: center;
    /* Center text vertically */
    justify-content: center;
    /* Center text horizontally */
    color: rgba(44, 156, 240, 1);
    cursor: pointer;
}

.apply span {
    line-height: 1;
    /* Adjust line height to vertically center text */
}

.couponCode{
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
}
.couponCode-2{
    font-size: 12px;
    font-family: "Poppins";
}
.removeCouponCode{
    color: rgba(44, 156, 240, 1);
    cursor: pointer;
}
.couponAmt{
    font-size: 16px;
    font-family: "Poppins";
}

.congr{
    font-size: 10px;
    font-family: "Poppins";
}


.proceedpay-div {
    width: 100%;
    background-color: #ffff;
    height: 80px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    padding: 1rem 0rem;
}

.proceedpay-btn {
    width: 275px;
    height: 54px !important;
    border-radius: 12px;
    background-color: rgba(44, 156, 240, 1);
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
    border: none;
    cursor: pointer;
}

.Base-pr {
    width: 70px;
    height: 23px;
    font-size: 12px;
    color: rgba(106, 106, 106, 1);
}

.base-rs {
    width: 43px;
    height: 17px;
    font-size: 12px;
    font-weight: bold;
}

.CGST {
    width: 155x;
    height: 23px;
    font-size: 12px;
    color: rgba(106, 106, 106, 1);
}

.CGST-rs {
    width: 36px;
    height: 17px;
    font-size: 12px;
    font-weight: bold;
}

.SGST {
    width: 155px;
    height: 23px;
    font-size: 12px;
    color: rgba(106, 106, 106, 1);
}

.SGST-rs {
    width: 36px;
    height: 17px;
    font-size: 12px;
    font-weight: bold;
}

.dropdown-text {
    width: 342.5px;
}

.arrowdown {
    font-size: 10px;
    cursor: pointer;
}
.backarrowlink{
    cursor: pointer;
}

@media screen and (max-width: 1180px) {
    .bookingticket {
        width: 80%;
        margin: auto;
    }
}

@media screen and (max-width: 969px) {
    .bookingticket {
        width: 80%;
        margin: auto;
    }
}

@media screen and (max-width: 870px) {
    .bookingticket {
        width: 90%;
        margin: auto;
    }

    .bookingbifargation {
        min-width: 156px;
        margin-left: 10px;
        padding: 0.5rem;
        border-radius: 0.5rem;

    }
}

@media screen and (max-width: 775px) {
    .bookingticket {
        width: 95%;
        margin: auto;
    }

    .bookingbifargation {
        min-width: 156px;
        padding: 0.5rem;
        margin-left: 10px;
        border-radius: 0.5rem;

    }
}

@media screen and (max-width: 586px) {
    #largeScreenViewPort {
        display: none;
    }

    #smallScreenViewPort {
        display: block;
    }

    .headerpart {
        height: 56px;
    }

    .topheading {
        font-family: "Poppins";
        font-size: 18px;
    }

    .backarrow {
        position: absolute;
        left: 10px;
    }

    .ticketbody {
        margin-top: 10px;
        padding: 1rem;
    }
 
    .summaryheading {
        font-family: "Poppins";
        font-size: 16px;
    }

    .ticket-Details {
        margin-bottom: 28px;
        min-height: 158px !important;
        border-radius: 1rem;
        padding: 0.5rem;
    }

    .ticketNAme {
        display: flex;
        justify-content: space-between;
        font-family: "Poppins";
        font-size: 16px;
        font-weight: 600;
    }

    .ticketprice {
        font-family: "Poppins";
        font-size: 18px;
        font-weight: 600;
    }

    .ticketdatetime {
        font-family: "Poppins";
        font-size: 14px;
        color: #6A6A6A;
        display: flex;
        justify-content: space-between !important;
    }

    .ticketname {
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 600;
    }

    .quantity {
        font-family: "Poppins";
        font-size: 16px;
        color: #6A6A6A;
    }
    .total{
        font-family: "Poppins";
        font-size: 20px;
        font-weight: 600;
    }
    .basic{
        font-family: "Poppins";
        font-size: 14px;
        color: #6A6A6A;
        font-weight: 300;
    }
    .basicvalue{
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 600;
    }

    .bookingDetails {
        margin-top: 32px;
        margin-bottom: 30%;
        border-radius: 1rem;
        padding: 0.5rem;  
    }

    .footerPart {
        width: 100%;
        margin-top: 32px;
        height: 30px !important;
        padding: 0px 5px;
        position: sticky;
        background: #ffff;
        bottom: 0;
        
    }

    .proceedpay-btn {
        height: 40px !important;
        width: 160px;
        font-size: 14px !important;
        font-family: "Poppins";
        color: white;
        border: none !important;
        border-radius: 0.5rem !important;
        margin-top: 0.5rem;
    }
    
}