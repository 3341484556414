.mobilesearchDiv {
    height: 3rem;
    display: flex;
}

.searchIcon-mobile {
    width: 15%;
    text-align: center;
    background-color: #EBEBEB;
    border-top-left-radius: 5rem;
    border-end-start-radius: 5rem;
    border: 1px solid #999999;
    border-right: none !important;
}

.searchTab-mobile {
    width: 85%;
    border-top-right-radius: 5rem;
    border-end-end-radius: 5rem;
    border: 1px solid #999999;
    border-left: none !important;
}

.searchTab-close {
    display: flex;
    align-items: center;
    margin: auto;
}

.searchTab-input {
    width: 100%;
    height: 100%;
    border-top-right-radius: 5rem;
    border-end-end-radius: 5rem;
    background-color: #EBEBEB !important;
    padding-left: 5px;
}

.searchTab-input:focus {
    outline: none;
    border: none;
}

.search_history {
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 600;
}

.history_clear {
    font-size: 12px;
    font-family: "Poppins";
    color: #2C9CF0;
}

.history {
    display: flex;
    flex-wrap: wrap;
    /* Ensures items move to the next line */
    gap: 10px;
    /* Adjusts spacing between items */
    justify-content: flex-start;
    /* Aligns items to the start */
}

.history_List {
    border-radius: 5rem;
    background-color: #EBEBEB;
    padding: 0.5rem 0.7rem;
    width: calc(100% / 3 - 8px);
    /* Ensures exactly 5 items per row */
    font-size: 14px;
    font-family: "Poppins";
    white-space: nowrap;
    text-align: center;
}

.categoryBrowser {
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 600;
}

.history .searchcategorycard {
    width: calc(100% / 3 - 10px);
    height: 85px !important;
    margin-left: 0px;
    border: none !important;
}

.history .categoryblock {
    position: relative;
    top: 2%;
    padding: 0.1rem 0.5rem !important;
    border-radius: 1rem;
    background-color: #FFFFFF;
    color: black;
    font-family: Poppins;
    width: 99%;
    height: 21px;
    font-weight: bold;
    font-size: 12px;
    z-index: 1;
    white-space: nowrap;
    border-bottom: 2px solid white;
    border-left: 2px solid white;
    border-right: 2px solid white;
}
.history .categorycard  .card-img {
    height: 55.35px !important;
    width: 100%;
    border-radius: 1rem;
    position: relative;
    top: 5%;
  }

  .searchcategory-img {
    width: 100%;
    /* height: 100px; */
    border-radius: 1rem;
    z-index: -1;
  }
.search_links{
    text-decoration: none;
    color: black;
    font-size: 14px;
}
.highlight{
    font-weight: bold;
}
.searchdata-image{
    width: 112px;
    height: 61px;
}