.location-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    /* Ensure the modal stays on top */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
}

.location-content {
    width: 70%;
    height: 261px;
}

.location-info {
    background-color: white;
    padding: 20px;
    padding-top: 5%;
    border-radius: 20px;
    position: relative;
    width: 100%;
    height: 250px;
}

.locationHead {
    margin-left: 2%;
    font-size: 26px;
    font-family: "Poppins";
}

.closebtn {
    position: relative;
    background-color: white;
    border-radius: 50%;
}

.close:hover {
    background-color: #f0f0f0;
    /* Change background color on hover */
}

.search-icon-location {
    position: absolute;
    left: 35px;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(44, 156, 240, 1);
    width: 26.73px;
    height: 26.73px;
}

.text-location {
    width: 113px;
    height: 21px;
    color: rgba(153, 153, 153, 1);
    font-size: 14px;
}

.SearchInput {
    border-radius: 5rem;
    width: 90%;
    height: 59px;
    padding: 0.5rem;
    padding-left: 3.5rem;
    margin-top: 3%;
    border: 1px solid #2C9CF0;
    /* box-shadow: 2px 2px 2px 2px #999999; */
}

.Search_Input_field {
    border: none !important;
    border-radius: 0rem 10rem 10rem 0rem;
    width: 100%;
    height: 100%;
    outline: none;
    /* Remove default outline */
    box-shadow: none;
    /* Remove any default shadow */
    cursor: pointer;
}

.Search_Input_field:not(:placeholder-shown) {
    border: none !important;
    outline: none;
    box-shadow: none;
}


.search_Input {
    border-radius: 0rem 10rem 10rem 0rem;
    /*top-left, top-right, bottom-right, bottom-left*/
    height: 59px;
    width: 100%;
}

.search_magnify {
    border-radius: 10rem 0rem 0rem 10rem;
    height: 59px;
    width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown-menu {
    width: 84%;
    margin-left: 6% !important;
}

.SearchInputIcon {
    position: relative;
    left: 6%;
}

/* .input-container :hover{
    border: 2px solid;
    color: rgba(44, 156, 240, 1);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
    border-radius: 50px;
} */

.detect-loc {
    width: 170px;
    height: 20px;
    /* Increase height for better visibility and interaction */
    margin-top: 56px;
    /* Adjusted margin-top for better spacing */
    cursor: pointer;
    /* Ensure the cursor indicates the button is clickable */
    margin: 0 auto;
    /* Center horizontally */
    margin-top: 56px;
}

.detect-button {
    width: 145px;
    height: 20px;
    border: none;
    background-color: white;
    color: rgba(153, 153, 153, 1);
    font-size: 14px;

}

.detect-icon {
    width: 20px;
    height: 20px;
    color: rgba(44, 156, 240, 1);


}

.searchdiv {
    border: 1px solid #2C9CF0;
    border-radius: 10rem;
    width: 90%;
    margin-left: 2%;
    margin-top: 3%
}

@media screen and (max-width:1150px) {
    .location-modal {
        display: flex;
        position: fixed;
        z-index: 9999;
        /* Ensure the modal stays on top */
        top: 0rem;
        left: 0rem;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        /* Semi-transparent background */
        border-radius: 20px;
    }

    .location-info {
        background-color: white;
        padding: 20px;
        padding-top: 5%;
        border-radius: 20px;
        position: relative;
        width: 100%;
        height: 250px;

    }

    .location-content {
        width: 70%;
        height: 261px;

    }

    .location-info {
        background-color: white;
        border-radius: 20px;
        padding-top: 5%;
        width: 100%;
        height: 80%;
    }

    .SearchInput {
        border-radius: 5rem;
        width: 90%;
        padding: 0.5rem;
        padding-left: 3rem;
    }

    .dropdown-menu {
        width: 82%;
    }

    .SearchInputIcon {
        position: relative;
        left: 6.9%;
    }

    .dropdown-menu {
        width: 82%;
        margin-left: 8.5% !important;
    }

}


@media screen and (max-width: 790px) {
    .location-modal {
        display: flex;
        position: fixed;
        z-index: 9999;
        /* Ensure the modal stays on top */
        top: 0rem;
        left: 0rem;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        /* Semi-transparent background */
        border-radius: 20px;
    }

    .location-info {
        background-color: white;
        padding: 20px;
        padding-top: 5%;
        border-radius: 20px;
        position: relative;
        width: 100%;
        height: 230px;

    }

    .location-content {
        width: 80%;
        height: 241px;

    }

    .location-info {
        background-color: white;
        border-radius: 20px;
        padding-top: 5%;
        padding-bottom: 5%;
        width: 100%;
        height: 100%
    }

    .SearchInput {
        border-radius: 5rem;
        width: 90%;
        padding: 0.5rem;
        padding-left: 3rem;
    }

    .SearchInputIcon {
        position: relative;
        left: 6%;
    }

    .dropdown-menu {
        width: 83%;
        margin-left: 8% !important;
    }
}

@media screen and (max-width:578px) {
    .location-modal {
        display: flex;
        position: fixed;
        z-index: 9999;
        /* Ensure the modal stays on top */
        top: 0rem;
        left: 0rem;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        /* Semi-transparent background */
        border-radius: 20px;
    }

    .locationHead {
        font-size: 18px;
        font-family: "Poppins";
    }

    .location-content {
        width: 88%;
        height: 144.74px;
    }

    .location-info {
        background-color: white;
        border-radius: 20px;
        padding-top: 5%;
        padding-bottom: 5%;
        width: 100%;
        height: 134.74px;
    }

    .searchdiv {
        border: 1px solid #2C9CF0;
        border-radius: 10rem;
        width: 90%;
        height: 39px;
        margin-left: 2%;
        margin-top: 3%
    }
    .search_magnify {
        border-radius: 10rem 0rem 0rem 10rem;
        height: 39px;
        width: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .search_Input {
        border-radius: 0rem 10rem 10rem 0rem;
        /*top-left, top-right, bottom-right, bottom-left*/
        height: 37px;
        width: 100%;
    }
    .Search_Input_field {
        border: none !important;
        border-radius: 0rem 10rem 10rem 0rem;
        width: 100%;
        height: 100%;
        outline: none;
        font-size: 16px !important;
        /* Remove default outline */
        box-shadow: none;
        /* Remove any default shadow */
        cursor: pointer;
    }
    .Search_Input_field:not(:placeholder-shown) {
        border: none !important;
        outline: none;
        box-shadow: none;
        font-size: 16px !important;
    }
    .SearchInput {
        border-radius: 5rem;
        width: 93%;
        height: 39px;
        padding: 0.4rem;
        padding-left: 2.7rem;
        border: 1px solid #2C9CF0 !important;
        box-shadow: none;
    }

    .SearchInputIcon {
        position: relative;
        left: 8.5%;
    }

    .dropdown-menu {
        width: 91%;
        margin-left: 6% !important;
    }
}