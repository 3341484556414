.form-control {
    -webkit-appearance: menulist; /* for Safari */
    -moz-appearance: menulist;    /* for Firefox */
    appearance: menulist;         /* for most browsers */
}
.input_style{
    border: 1px solid #259bf4 !important;
  }
.btn_primary{
    background-color: #259bf4;
    border-radius: 0.5rem;
    border: none;
    padding: 0.5rem 1rem;
}