/* Ensures the body and main container take full height */
html, body {
    height: 100%;
    margin: 0;
  }
  
  .main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .main-content {
    flex: 1;
  }
  