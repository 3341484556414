#mobileView{
    display: none;
}

.ticketsbooked-modal, .personaldet-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999; /* Ensure the modal stays on top */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    font-family: 'Poppins', sans-serif; /* Applying Poppins font family */
}

.containerr {
    width: 546px;
    height: 648px;
    margin: auto;
    background-color: none;
    text-align: center;
    /* Add the following properties */
    display: flex;
    justify-content: center;
    align-items: center
    /* Set the height to 100% of the viewport height */
}

.cont-pers {
    width: 539px;
    height: 525px;
    border-radius: 20px;
    margin: auto;
    padding: 20px;
    background-color: white;
    text-align: center;
    position: relative;
}

.book-yr-seat {
    height: 32px;
    text-align: center;
    margin: auto;
}

.book {
    display: inline-block;
    margin: 0;
    font-size: 21px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.your-fs {
    display: inline-block;
    margin: 0;
    font-size: 21px;
    font-family: "Poppins", sans-serif;
    color: rgba(44, 156, 240, 1);
    font-weight: bold;
}

.head-pers {
    text-align: start;
    /* width: 321px; */
    height: 60px;
    font-size: 40px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 13px;
    margin-top: 18px;

}

.details-pers-inp-1,
.details-pers-inp-2 {
    width: 451px;
    margin-bottom: 22px;
    border: 1.2px solid rgba(44, 156, 240, 1);
    border-radius: 9px;
    font-size: 15px;
    color: rgba(128, 128, 128, 1);
    padding: 0.5rem;

}

.details-pers-inp-3 {
    width: 451px;
    border: 1.2px solid rgba(44, 156, 240, 1);
    border-radius: 9px;
    font-size: 15px;
    color: rgba(128, 128, 128, 1);
    padding: 0.5rem;
}

.mail-recieve {
    /* width: 176px; */
    height: 15px;
    font-size: 11px;
    color: rgba(106, 106, 106, 0.56);
    font-family: "Poppins", sans-serif;
    display: flex;
    justify-content: start;
    margin-top: 8px;
}

.gender-select {
    width: 202px;
    height: 49px;



}

.progress-bar-container {
    width: 100%;
    height: 5px;
    background-color: #f0f0f0;
    margin-top: 20px;
  }
  
  .progress-bar {
    width: 0;
    height: 100%;
    background-color: #007bff;
    animation: progressAnimation 2s linear forwards;
  }
  
  @keyframes progressAnimation {
    100% {
      width: 100%;
    }
  }
  
.pers-gender {
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    margin-left: 5px;
} 

.gender {
    display: inline-block;
    text-align: start;
    margin-right: 5%;
}

.DOBGender-pers {
    display: flex;
    /* Use flexbox to align items in a row */

}

.pers-accept-div {
    margin-top: 37px;
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#terms-pers {
    width: 16px;
    height: 16px;
    color: rgba(128, 128, 128, 1);
    border-radius: 5px;
    margin-right: 5px;
  
}

.text-terms-acc {
    width: 269px;
    height: 20px;
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    color: rgba(44, 156, 240, 1);
    margin-bottom: -5px;

}

.pers-pay-btn {
    margin-top: 33px;
    width: 214px;
    height: 45px;
    border-radius: 10px;
    background-color: rgba(44, 156, 240, 1);
    border: none;
    color: rgba(255, 255, 255, 1);
    font-size: 16px;

}

.closebtn{
    position: absolute;
    top: -10px;
    right: -12px;
    cursor: pointer;
    font-size: x-large;
}
.closebtn:hover {
    border: 2px solid gray;
    background-color: navy !important;
    color: white;
  }

@media screen and (max-width: 768px) {
    .containerr {
        width: 446px;
        height: 548px;
        margin: auto;
        background-color: none;
        text-align: center;
        /* Add the following properties */
        display: flex;
        justify-content: center;
        align-items: center;
        /* Set the height to 100% of the viewport height */
    }
    
    .cont-pers {
        width: 439px;
        height: 535px;
        border-radius: 20px;
        margin: auto;
        padding: 20px;
        background-color: white;
        text-align: center;
        position: relative;
    }
}
@media screen and (max-width: 568px) {
    #mobileView{
        display: block;
    }
    #desktopView{
        display: none;
    }
    .containerr {
        width: 318px;
        height: 574px;
        margin: auto;
        background-color: none;
        text-align: center;
        /* Add the following properties */
        display: flex;
        justify-content: center;
        align-items: center;
        /* Set the height to 100% of the viewport height */
    }
    
    .cont-pers {
        width: 318px;
        height: 574px;
        border-radius: 20px;
        margin: auto;
        padding: 20px;
        background-color: white;
        text-align: center;
        position: relative;
    }
    .book{
        font-family: "Poppins";
        font-size: 16px;
    }
    .front-seat{
        font-family: "Poppins";
        font-size: 16px;
        color: #2C9CF0;
    }
    .heading{
        font-family: "Poppins";
        font-size: 24px;
        text-align: center;
        margin-top: 25px;
        margin-bottom: 14px;
    }
    .pers-pay-btn {
        margin-top: 20px;
        width: 214px;
        height: 45px;
        border-radius: 10px;
        background-color: rgba(44, 156, 240, 1);
        border: none;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
    
    }
    .datepicker{
        height: 50px !important;
    }
    .pers-accept-div{
        margin-top: 5px;
    }
    #terms-pers {
        width: 10px;
        height: 10px;
        color: rgba(128, 128, 128, 1);
        border-radius: 5px;
        margin-right: 2px;
      
    }
    .text-terms-acc {
        width: 169px;
        height: 10px;
        color: #2C9CF0;
        font-family: "Poppins";
        font-size: 8px;
        margin-bottom: 2px;
    
    }
}