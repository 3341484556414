.ticketlist{
    max-width: 729px;
    margin: auto;
    margin-top: 23px;
}
#smalldeviceview{
    display: none !important;
}
.event-heading{
    display: flex;
    justify-content: center;
}
.backarrowlink{
    position: relative;
    left: -30%;
} 
.link-dark:hover{
    color: #2C9CF0;
}
.hedingcontent{
    margin-left: 20%;
}
.event-name{
    font-family: "Poppins";
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}
.event-detail{
    font-family: "Poppins";
    font-size: 15px;
    text-align: center;
}
.head{
    font-family: "Poppins";
    font-size: 20px;
    font-weight: bold;
    margin-top: 21px;
}
.listoftickets{
    height: 76px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #D9D9D9;
    margin: 0px 2px;
    margin-top: 11px;
    padding: 0.5rem;
}
.ticketDetails{
    display: flex;
    flex-direction: column;
}
.ticketname{
    font-family: "Poppins";
    font-size: 20px;
    font-weight: bold;
}
.ticketdescription{
    font-family: "Poppins";
    font-size: 15px;
}
.ticketcount{
    font-family: "Poppins";
    font-size: 20px;
}
.couting{
    font-size: 23px;
    margin-top: 0.2rem;
    cursor: pointer;
}
.footerPart{
    width: 100%;
    height: 80px;
    position: fixed; 
    background: #ffff;
    bottom: 0;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);
}
.footer-content{
    max-width: 729px;
    height: 50px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ticketPrice{
    font-family: "Poppins";
    font-size: 24px;
}
.totalCount{
    font-family: "Poppins";
    margin-top: -10px;
    font-size: 20px;
    color: #6A6A6A;
}
.proceedbtn{
    color: white;
    font-size: 14px !important;
    width: 124px;
    height: 45px;
    border: none !important;
    border-radius: 0.5rem;
}
.btnisDisabled{
    background: #6A6A6A;
}
.btnisEabled{
    background: #2C9CF0;
}
.add-btn{
    background: #2C9CF0;
    color: #ffff;
    border: none;
    border-radius: 1rem;
    padding: 0.1rem 1rem;
    font-size: 15px;
}

/* Mobile view */
@media screen and (max-width:578px){
    .select-ticket {
        height: 98vh !important;
        overflow-y: hidden;
        padding-bottom: 80px; /* Add padding to prevent overlap */
    }
    
    .ticketlist{
        max-width: 100%;
        margin-top: 0px;
        margin-bottom: 172px;
    }
    .event-heading{
        display: flex;
        padding: 0rem 0.5rem;
    } 
    #smalldeviceview{
        display: block !important;
    }
    #largedeviceview{
        display: none !important;
    }
    .backarrowlink{
        position: relative;
        left: 0;
    }
    .event-name{
        font-family: "Poppins";
        font-size: 18px;
        text-align: center;
    }
    .event-detail{
        font-family: "Poppins";
        font-size: 14px !important;
        text-align: center;
    }
    .head{
        font-family: "Poppins";
        font-size: 16px !important;
        font-weight: bold;
        margin: 0px 1rem;
        margin-top: 16px;
    }
    .listoftickets{
        min-height: 56px !important;
        display: flex;
        justify-content: space-between;
        /* box-shadow: 1px 2px #bdbcbc; */
        border: 1px solid #D9D9D9;
        margin: 0px 1rem;
        margin-top: 16px;
        padding: 0.1rem 0.5rem;
    }
    .ticketname{
        font-family: "Poppins";
        font-size: 14px;
        font-weight: bold;
        margin-top: 5px;
    }
    .ticketdescription{
        font-family: "Poppins";
        font-size: 14px;
        margin-top: 10px;
        color: #6A6A6A;
    }
    .ticketcount{
        font-family: "Poppins";
        font-size: 18px;
        margin-top: 2.5px;
    }
    .couting{
        font-size: 25px;
    }
    
    .smallfooterPart {
        width: 100%;
        height: 60px;
        padding: 3px 5px !important;
        position: fixed; 
        background: #fff;
        bottom: 0; /* Change from bottom: 1% to bottom: 0 */
        left: 0; /* Ensure it stays aligned with the screen */
        box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2);
    }
    
    .ticketPrice{
        font-size: 25px;
        font-family: "Poppins";
        font-weight: bold !important;
    }
    .totalCount{
        font-family: "Poppins";
        font-size: 16px;
        color: #6A6A6A;
    }
    .proceedbtn{
        height: 40px;
        width: 160px;
        font-size: 14px !important;
        font-family: "Poppins";
        color: white;
        border: none !important;
        border-radius: 0.5rem !important;
    }

}