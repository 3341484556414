#largeScreenDiv {
    display: block;
}

#smallScreenDiv {
    display: none;
}

.navigationBar {
    background: #04092c;
    display: flex;
    height: 80px;
    color: white;
    box-shadow: 2px 2px 8px #999999;
}

.logoDiv {
    display: flex;
    align-items: center;
}

.logoImg {
    margin-left: 16%;
    width: 201px;
    height: 55px;
}

.navDiv {
    width: 80%;
    margin-left: 7%;
    display: flex;
}

.searchDiv {
    width: 64%;
}

.setcentersearch {
    margin: 5.5% 0%;
}

.search {
    display: flex;
    height: 36px;
}

.searchIcon {
    color: #2C9CF0;
    z-index: 1;
    position: relative;
    top: 5px;
    left: 1.5%;
}

.search-input {
    border-radius: 10rem;
    height: 36px;
    width: 30.35%;
    position: absolute;
    padding-left: 3%;
    border: none;
    font-family: "Poppins";
    font-size: 14px;
}

.searchSuggestions {
    left: -10%;
}

.navContent {
    display: flex;
    align-items: center;
    min-width: 45%;
    font-size: 16px;
}

.listevent {
    display: flex;
    align-items: center;
    height: 33px;
    width: 20rem;
    justify-content: end;
}

.btnlink {
    background: none;
    border: none;
    color: white;
    font-size: small;
    font-family: Poppins;
    font-weight: 400;
}

.locationDiv {
    text-align: center;
    margin-left: 15% !important ;
    width: 55% !important;
    height: 26.73px;
}

.loactionlink {
    text-decoration: none;
    color: #999999;
    font-size: small;
    font-family: Poppins;
    font-weight: 400;
}

.locationIcon {
    font-size: x-large;
    color: #2C9CF0;
}

.userDiv {
    width: 15%;
    display: flex;
    align-items: center;
}

.user {
    height: 28px;
    display: flex;
    align-items: end;
    margin-left: 15%;
}

.userlink {
    text-decoration: none;
    color: #ffffff;
    font-size: small;
    font-family: Poppins;
    font-weight: 400;
}

@media screen and (max-width:1340px) {
    .navDiv {
        width: 75%;
        margin-left: 7%;
        display: flex;
    }

    .searchDiv {
        width: 62%;
    }

    .setcentersearch {
        margin: 4.5% 0%;
    }

    .navContent {
        display: flex;
        align-items: center;
        width: 40%;
    }

    .locationDiv {
        margin-left: 20%;
        width: 35%;
        height: 26.73px;
    }

    .userDiv {
        width: 12%;
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width:1145px) {
    .logoImg {
        margin-left: 10%;
        width: 201px;
        height: 55px;
    }

    .navDiv {
        width: 65%;
        margin-left: 5%;
        display: flex;
    }

    .searchDiv {
        width: 60%;
    }

    .setcentersearch {
        margin: 5.9% 0%;
    }

    .search-input {
        border-radius: 10rem;
        height: 36px;
        width: 30.35%;
        position: absolute;
        padding-left: 4%;
        border: none;
    }

    .navContent {
        display: flex;
        align-items: center;
        width: 50%;
    }

    .locationDiv {
        margin-left: 5%;
        width: 45%;
        height: 26.73px;
    }

    .userDiv {
        width: 9%;
        display: flex;
        align-items: center;
    }

    .user {
        height: 28px;
        display: flex;
        align-items: center;
        margin-left: -13%;
    }
}

@media screen and (max-width:865px) {
    .logoImg {
        margin-left: 5%;
        width: 201px;
        height: 55px;
    }

    .navDiv {
        width: 60%;
        margin-left: 3%;
        display: flex;
    }

    .searchDiv {
        width: 50%;
    }

    .setcentersearch {
        margin: 8.9% 0%;
    }

    .search-input {
        border-radius: 10rem;
        height: 36px;
        width: 28.35%;
        position: absolute;
        padding-left: 4%;
        border: none;
    }

    .navContent {
        display: flex;
        align-items: center;
        width: 55%;
        margin-left: 2%;
    }

    .locationDiv {
        margin-left: 3%;
        width: 50%;
        height: 26.73px;
    }

    .userDiv {
        width: 9%;
        display: flex;
        align-items: center;
    }

    .user {
        height: 28px;
        display: flex;
        align-items: center;
        margin-left: -20%;
    }
}

@media screen and (max-width: 830px) {
    #largeScreenDiv {
        display: none;
    }

    #smallScreenDiv {
        display: block;
    }

    .navbar {
        background: #04092c;
        display: flex;
        height: 56px;
        color: white;
        display: flex;
    }

    /* .dropdown-menu {
        display: block !important;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }
    
    .dropdown:hover .dropdown-menu,
    .dropdown-menu.show {
        visibility: visible;
        opacity: 1;
    } */
    

    .togglebtn {
        margin-left: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .collapseNavbar {
        position: absolute !important;
        top: -17px !important;
        left: -17px !important;
        width: 278px !important;
        height: 1728px !important;
        list-style: none !important;
    }

    .userProfile {
        text-align: center;
        height: 96px;
        margin-top: 35px;
    }

    .userProfilePic {
        width: 68px;
        height: 68px;
        border-radius: 100%;
    }

    .sidenavcontent {
        margin-top: 10%;
        margin-left: 27px;
    }

    .sidenavcontent ul {
        list-style: none !important;
        font-size: 16px;
    }

    .nav-content {
        margin-top: 1%;
    }

    .logo-Image {
        height: 100%;
    }

    .logomobileView {
        width: 167px;
        height: 45px;
    }

    .searchbtn {
        margin-right: 18px;
        margin-bottom: 2%;
    }
    .searchDiv{
        width: 90%;
        margin-left: 2%;
    }
    .searchIcon{
        margin-left: 0.5rem;
    }
    .search-input {
        border-radius: 10rem;
        height: 36px;
        width: 95.35%;
        position: absolute;
        padding-left: 12%;
        border: 2px solid #2C9CF0 !important;
        font-family: "Poppins";
        font-size: 14px;
    }
    .searchSuggestions {
        left: -0%;
    }
}