.sideBar {
    width: 243px !important;
    max-width: 100%;
}

.userProfile {
    margin: 49px 0px;
    text-align: center;
}

.eventImg {
    width: 68px;
    height: 68px;
    border-radius: 50%;
}

.side-NavBar {
    margin-top: 49px;
}

.nav-contents {
    font-family: "Poppins" !important;
    font-size: 16px !important;
    height: 55px !important;
    padding-left: 21px !important;
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
}

/* Active link styles */
.nav-contents.bg-primary {
    background-color: #007bff; /* Bootstrap primary color */
}

.nav-contents.bg-primary a {
    color: white !important;
}

.profileheading {
    font-family: "Poppins" !important;
    font-size: 25px !important;
    color: #2C9CF0;
}

.listheading {
    font-family: "Poppins";
    font-size: 20px;
}

.poppin18 {
    font-family: "Poppins";
    font-size: 15px;
}

.poppin14 {
    font-family: "Poppins";
    font-size: 12px;
    color: #6A6A6A;
}

.lists {
    margin: 0rem 1rem;
}
@media screen and (max-width: 768px){
    .nav-contents {
        font-family: "Poppins" !important;
        font-size: 12px !important;
        height: 52px !important;
        padding-left: 21px !important;
        border-radius: 0.3rem;
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width: 830px){
    #usersidebar{
        display: none;
    }
}
