.login-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    /* Ensure the modal stays on top */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    /* Semi-transparent background */
}

/* .containerr {
    width: 100%;
    margin: auto;
    background-color: none;
    text-align: center;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    border: 2px solid yellow !important;
  } */

.coplogin-containermodal {
    width: 90%;
    max-width: 450px;
    /* You can set a max width for larger screens */
    border-radius: 20px;
    background-color: white;
    padding: 0.5% 2% !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Add some shadow for depth */
    text-align: center !important;
}

.book {
    display: inline-block;
    margin: 0;
    font-size: 21px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.your-fs {
    display: inline-block;
    margin: 0;
    font-size: 21px;
    font-family: "Poppins", sans-serif;
    color: rgba(44, 156, 240, 1);
}

.cop-login {
    text-align: start;
    width: 107px;
    height: 25px;
    font-size: 25px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-top: -2%;
}

.close {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    /* Circular box */
    position: absolute;
    top: -10px;
    /* Adjust position */
    right: -10px;
    /* Adjust position */
    cursor: pointer;
    padding: 5px;
    /* Adjust padding as needed */
    border: solid 1.5px black;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    /* Ensure it's above other content */
    font-weight: 500;
    font-size: 25px;
    padding-bottom: 5px;
}

.copclosebtnX {
    position: relative;
    top: -20px;
    right: -84%;
    cursor: pointer;
    font-size: x-large;
  }

.div-num {
    justify-content: start;
}

.enter-number {
    font-family: "Poppins", sans-serif;
    display: flex;
    justify-content: start;
    font-weight: 500;
    font-size: 14px;
}

.input-number[type="tel"] {
    width: calc(100% - 40px);
    padding: 10px;
    margin-bottom: 30px;
    border-radius: 9px;
    box-sizing: border-box;
    width: 451px;
    height: 57px;
    border: solid 1.7px rgba(44, 156, 240, 1);
    font-size: 14px;
    font-family: "Poppins", sans-serif;
}

input::placeholder {
    font-size: 12px;
    /* Adjust size as needed */
    color: gray;
    /* Optional: Change color */
    opacity: 1;
    /* Ensure visibility */
}

.LoginbookBtn {
    width: 214px;
    padding: 5px;
    border: none;
    border-radius: 5px;
    background-color: rgba(44, 156, 240, 1);
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
}

.resend-otp {
    display: flex;
    justify-content: start;
    margin-top: 20px;
}

.resend-text {
    font-size: 13px;
    font-family: "Poppins", sans-serif;
}

.isResendDisabled {
    color: rgb(122, 186, 235);
}

.isResendEnabled {
    color: rgb(6, 132, 228);
}

.countdown {
    margin-left: 0.3rem;
    font-size: small;
}

.seconds {
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    margin-left: 5px;
    font-weight: 500;
}


.login-btn {
    width: 214px;
    height: 45px;
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 33px;
    border: none;
    border-radius: 5px;
    background-color: rgba(44, 156, 240, 1);
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
}

.input-otp {
    width: 451px;
    height: 57px;
    border: solid 1.7px rgba(44, 156, 240, 1);
    border-radius: 9px;

}

.closebtn {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
    font-size: x-large;
}

.closebtn:hover {
    border: 2px solid gray;
    background-color: navy !important;
    color: white;
}

.btn {
    width: 40%;
    margin: 0rem 1rem;
}

.mobileinput {
    height: 40px;
    font-size: 18px !important;
}

@media screen and (max-width: 980px) {
    .login-container {
        width: 120%;
        height: 406px;
        border-radius: 20px;
        margin: auto;
        margin-left: -10%;
        background-color: white;
        text-align: center;
        position: relative;
        padding: 8%;

    }
}

@media screen and (max-width: 698px) {
    .login-container {
        width: 150%;
        height: 406px;
        border-radius: 20px;
        margin: auto;
        margin-left: -20%;
        background-color: white;
        text-align: center;
        position: relative;
        padding: 8%;

    }
}

@media screen and (max-width: 460px) {
    .login-container {
        width: 180%;
        height: 406px;
        border-radius: 20px;
        margin: auto;
        margin-left: -40%;
        margin-top: -50%;
        background-color: white;
        text-align: center;
        position: relative;
        padding: 8%;

    }

    .mobileinput {
        height: 35px;
        font-size: 16px !important;
    }
}



@media screen and (max-width: 1139px) {
    .copclosebtnX {
      position: relative;
      top: -2rem;
      right: -83%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 999px) {
    .copclosebtnX {
      position: relative;
      top: -2rem;
      right: -82.5%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 980px) {
    .login-containermodal {
      width: 90%;
      max-width: 450px; /* You can set a max width for larger screens */
      border-radius: 20px;
      background-color: white;
      padding: 3% !important;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add some shadow for depth */
      text-align: center !important;
    }
    .login-container {
      width: 90%;
      max-width: 90%; /* Adjust width for smaller screens */
    }
    .copclosebtnX {
      position: relative;
      top: -2.5rem;
      right: -86%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 950px) {
    .copclosebtnX {
      position: relative;
      top: -2.7rem;
      right: -85.5%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 914px) {
    .copclosebtnX {
      position: relative;
      top: -2.7rem;
      right: -84.5%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 835px) {
    .copclosebtnX {
      position: relative;
      top: -2.5rem;
      right: -83.5%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 768px) {
    .copclosebtnX {
      position: relative;
      top: -2.2rem;
      right: -83.5%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 680px) {
    .copclosebtnX {
      position: relative;
      top: -2.2rem;
      right: -82%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 630px) {
    .copclosebtnX {
      position: relative;
      top: -2rem;
      right: -82.5%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 580px) {
    .copclosebtnX {
      position: relative;
      top: -2rem;
      right: -80.5%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 458px) {
    .copclosebtnX {
      position: relative;
      top: -1.5rem;
      right: -80.5%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 414px) {
    .login-containermodal {
      width:90%;
      max-width: 450px; /* You can set a max width for larger screens */
      border-radius: 20px;
      background-color: white;
      padding: 5% !important;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add some shadow for depth */
      text-align: center !important;
    }
    .copclosebtnX {
      position: relative;
      top: -1rem;
      right: -85.2%;
      cursor: pointer;
      font-size: x-large;
    }
  }
