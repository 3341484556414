.trendingeventDiv{
    width: 88%;
    margin: auto;
    margin-top: 38px;
}
.trendingHeading {
    font-family: Poppins !important;
  }
.trendingeventlist{
    width: 97%;
    margin: auto !important;
}
.trendingCard{
    width: 319.05px;
    /* min-height: 298.52px; */
    height: auto;
    margin-right: 66px;
    margin-bottom: 47px;
    border: none !important;
    border-radius: 1rem !important
}
.trendingeventlist .col-lg-4:nth-child(3n) .trendingCard {
    margin-right: 0 !important;
}
.trendingcardImg{
    height: 160.11px;
    border-radius: 1rem 0rem 1rem 0rem;
}.trendingImg{
    width: 100%;
    height: 160.11px;
    border-radius: 1rem 1rem 0rem 0rem;
}
.trendingcardContent{
    /* min-height: 80px !important; */
    display: flex;
    align-items: center;
}
.month, .date, .eventhead, .eventvenue,.eventprice{
    font-family: "Poppins", sans-serif !important;
}
.month{
    font-size: small !important;
    text-transform: uppercase;
} .date{
    font-size: 28.43px !important;
    margin-top: -10px;
} .eventhead{
    font-size: 16px !important;
} .eventvenue,.eventprice{
    font-size: 14px !important;
    font-family: "Poppins", sans-serif !important;
}
.statusAlert{
    height: 22px;
    font-family: "Poppins";
    font-size: 14px;
    background: #FFE600;
    padding-left: 0.5rem;
}

@media screen and (max-width:1260px) {
    .trendingeventlist{
        width: 99%;
        margin: auto !important;
    }
    .trendingCard{
        width: 310.05px;
        height: auto;
        margin-right: 66px;
        margin-bottom: 47px;
        border-radius: 1rem !important;
    }
}
@media screen and (max-width:1110px) {
    .trendingeventlist{
        width: 100%;
        margin: auto !important;
    }
    .trendingCard{
        width: 100%;
        height: auto;
        margin-right: 66px;
        margin-bottom: 47px;
        border-radius: 1rem !important;
    }   
}
@media screen and (max-width:530px) {
    .trendingeventDiv{
        width: 90%;
        margin: auto;
        margin-left: 7% !important;
        margin-top: 19px;
    }
    .trendingeventlist{
        width: 100%;
        margin: auto !important;
    }
    .trendingCard{
        width:99%;
        height: auto;
        margin-right: 17px !important;
        margin-bottom: 22px;
        border-radius: 1rem !important;
    }
    .trendingeventlist .col-lg-6:nth-child(2n) .trendingCard {
        margin-right: 0 !important;
    }
    .trendingcardImg{
        height: 155.42px;
        border-radius: 1rem 0rem 1rem 0rem;
    }
    .trendingImg{
        width: 100%;
        height: 100%;
        border-radius: 1rem 1rem 0rem 0rem;
    }
    .trendingcardContent{
        /* min-height: 99.42px !important; */
        display: flex;
        align-items: start;
    }
    .month{
        padding-top: 0.2rem;
        font-size: 20px !important;
        text-transform: uppercase;
    } .date{
        font-size: 25px !important;
        margin-top: -5px;
    } .eventhead{
        font-size: 18px !important;
    } .eventvenue,.eventprice{
        font-size: 12px !important;
        font-family: "Poppins", sans-serif !important;
    }
}
@media screen and (max-width:350px) {
    .trendingeventDiv{
        width: 98% !important;
        margin: auto;
        margin-top: 16px;
    }
    .trendingeventlist{
        width: 100%;
        margin: auto !important;
    }
    .trendingCard{
        width: 100%;
        height: auto !important;
        margin-right: 7px;
        margin-bottom: 22px;
        border-radius: 1rem !important;
    }
    .trendingeventlist .col-lg-6:nth-child(2n) .trendingCard {
        margin-right: 0 !important;
    }
    .trendingcardImg{
        height: 141.42px;
        border-radius: 1rem 0rem 1rem 0rem;
    }.trendingImg{
        width: 100%;
        height: 141.42px;
        border-radius: 1rem 1rem 0rem 0rem;
    }
    .trendingcardContent{
        min-height: 98.58px;
    }
    .month{
        padding-top: 0.2rem;
        font-size: 20px !important;
        text-transform: uppercase;
    } .date{
        font-size: 25px !important;
        margin-top: -5px;
    } .eventhead{
        font-size: 18px !important;
    } .eventvenue,.eventprice{
        font-size: 12px !important;
        font-family: "Poppins", sans-serif !important;
    }
}