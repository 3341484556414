#mobileView {
    display: none;
}

.productDetailDiv {
    margin-top: 54px;
}

.detailDiv {
    width: 90%;
    margin: auto !important;
}

.Event_imageDiv {
    width: 680px;
    height: 319px;
    margin: auto;
}

.event_name {
    font-weight: bold;
    font-size: 24px;
    font-family: "Poppins";
}

.event-category {
    font-size: 12px;
    font-family: "Poppins";
    padding: 0.1rem 0.5rem;
    background: #2C9CF0;
    color: #ffffff;
    border-radius: 1rem;
}

.event-detail {
    font-size: 16px !important;
    font-family: "Poppins";
    color: #6A6A6A;
}

.directionspan {
    font-family: "Poppins";
    font-size: 10px !important;
    color: #2C9CF0;
    margin-left: 3%;
    text-decoration: none !important;
}

.event-timing {
    font-size: 15px;
    font-family: "Poppins";
}

.ticket-amt {
    font-size: 30px;
    font-weight: bold;
    font-family: "Poppins";
}

.booking-btn {
    background: #2C9CF0;
    color: #ffffff;
    width: 218px;
    height: 50px;
    font-family: "Poppins";
    border: none;
}

.aboutArtistDiv {
    width: 95%;
    font-size: 25px;
    margin: auto !important;
    margin-top: 87px !important;
}

.artistSocialdetail {
    width: 95%;
    overflow-x: auto; /* Enable horizontal scrolling */
    display: flex; /* Use flexbox for horizontal layout */
    gap: 10%; /* Add spacing between items */
}

.artistItem {
    flex: 0 0 auto; /* Prevent shrinking and allow scrolling */
    margin-right: 10px;
    width: 25%;
    text-align: center; /* Center the text and image */
    word-wrap: break-word; /* Break long words */
    overflow-wrap: break-word; /* Ensure text wraps */
    white-space: normal; /* Allow wrapping inside the item */
}

.artist-name {
    font-size: 1rem; /* Adjust font size as needed */
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal; /* Ensure text can wrap */
    line-height: 1.5;
    text-align: center;
    margin-top: 0.5rem;
}

.aboutArtist {
    min-height: 170px !important;
    padding: 0.5rem 1rem;
    margin-bottom: 10px !important;
}

.profileHead{
    font-family: "Poppins";
    font-size: 22px;
}
.profile-Head{
    font-family: "Poppins";
    font-size: 20px;
}
.profilesub{
    font-family: "Poppins";
    font-size: 16px;
}
.readmore{
    font-size: 12px;
}

.user-image {
    width: 77px;
    height: 77px;
    border-radius: 50%;
    object-fit: cover;
}

.venueDiv {
    width: 95%;
    font-size: 25px;
    margin: auto !important;
    margin-top: 58px !important;
}

.venueMap {
    width: 100%;
    min-height: 17rem;
    max-height: 17rem;
    text-align: center;
    font-weight: bold;
    font-size: xx-large;
    background-color: rgb(221, 217, 217);
}

.moreInfo {
    width: 90% !important;
    min-height: 16rem;
    max-height: 16rem;
}

.extraDetail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    height: 38px;
    background-color: #FFFF;
    box-shadow: 2px 2px 4px 0.1px #d7d5d5 ;
    border-radius: 0.5rem;
    cursor: pointer;
}

/* .arrowdown {
    font-size: 16px !important;
} */

#venueLoyout {
    width: 70%;
    margin: auto;
}

.layoutDiv {
    width: 95% !important;
    font-size: 25px;
    margin: auto !important;
    margin-top: 47px !important;
}

.layoutimgdiv {
    height: 225px;
    width: 95%;
}
.layoutImg{
    object-fit: contain;
}

.lastDiv {
    width: 90%;
    margin-left: 3.5%;
    margin-top: 70px !important;
    margin-bottom: 246px !important;
}


.event-schedule {
    display: flex;
    flex-direction: row;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    justify-content: space-between;
}

.guidelines {
    font-size: 20px;
}

@media screen and (max-width:1215px) {
    .Event_imageDiv {
        width: 500px;
        height: 450px;
        margin: auto;
    }

    .event-info {
        margin-left: 5%;
    }
}

@media screen and (max-width:1145px) {
    .detailDiv {
        width: 98%;
        margin: auto !important;
    }

    .Event_imageDiv {
        width: 500px;
        height: 450px;
        margin: auto;
    }

    .event-info {
        margin-left: 10%;
    }
}

@media screen and (max-width:995px) {
    .detailDiv {
        width: 98%;
        margin: auto !important;
    }

    .Event_imageDiv {
        width: 400px;
        height: 400px;
        margin: auto;
    }

    .event-info {
        margin-left: 10%;
    }

    .event_name {
        font-weight: bold;
        font-size: 45px;
        font-family: "Poppins";
    }

    .event-category {
        font-size: 11px;
        font-family: "Poppins";
        padding: 0.1rem 0.5rem;
        background: #2C9CF0;
        color: #ffffff;
        text-align: center;
    }

    .event-detail {
        font-size: 25px;
        font-family: "Poppins";
        color: #6A6A6A;
    }

    .event-timing {
        font-size: 20px;
        font-family: "Poppins";
        margin-bottom: 5%;
    }

    .event-schedule {
        display: flex;
        flex-direction: column;
        margin-top: 5px !important;
    }

    .ticket-amt {
        font-size: 30px;
        font-weight: bold;
        font-family: "Poppins";
        margin-top: 5px;
    }

    .booking-btn {
        margin-top: 5px;
        background: #2C9CF0;
        color: #ffffff;
        width: 190px;
        height: 69px;
        font-family: "Poppins";
        border: none;
    }
}


@media screen and (max-width: 586px) {
    #mobileView {
        display: block;
    }

    #desktopView {
        display: none;
    }

    .Event_imageDiv {
        width: 103.4%;
        height: 187px;
    }

    .event-category {
        max-width: 7rem !important;
        background: #2C9CF0;
        color: #ffffff;
        font-size: 12px;
        margin-bottom: 4px;
    }

    .event-lang {
        font-family: "Poppins";
        font-size: 16px;
        color: #6A6A6A;
    }
    .event-datetime {
        font-family: "Poppins";
        font-size: 15px;
        margin: 0.7rem 0rem 0rem 0rem;
    }

    .event-price {
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 60px;
        padding: 0rem 1rem;
        box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2), 0 2px 8px rgba(94, 94, 94, 0.2);
        background:white;
        z-index: 1000; 
    }

    .event-ticket {
        font-size: 25px;
        font-family: "Poppins";
        font-weight: bold;
    }

    .btn-booking {
        height: 40px;
        width: 160px;
        font-size: 14px !important;
        font-family: "Poppins";
        background: #2C9CF0;
        border: none !important;
        border-radius: 0.5rem !important;
    }

    .artistDiv {
        min-height: 174px;
        width: 95%;
        margin: auto;
        margin-top: 23px;
        font-family: "Poppins";
        font-size: 15px;
        border: 1px solid #F5F5F5 !important;
    }
    .artistDivabout{
        min-height: 85px;
        width: 95%;
        margin: auto;
        font-family: "Poppins";
        font-size: 15px;
        border: 1px solid #F5F5F5 !important;
    }

    .artistHeading {
        font-family: "Poppins";
        font-size: 16px;
    }

    .contents {
        font-family: "Poppins";
        font-size: 14px;
    }

    .user-image {
        width: 61px;
        height: 61px;
        border-radius: 50%;
    }

    .extraDetail {
        width: 103%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        font-family: "Poppins";
        font-size: 18px;
        background-color: #FFFF;
        padding: 0.1rem 2rem;
        margin-bottom: 0.5rem;
        box-shadow: 2px 2px 4px 0.1px #d7d5d5 ;
    }

    #venueLoyout {
        width: 98%;
        margin: auto;
        height: 264px;
    }
    .readmore{
        font-size: 10px;
    }
}


.primarycolor{
    color: #2C9CF0;
}