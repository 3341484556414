.carouselDiv {
    width: 90%;
    height: 350px;
    margin: auto;
    margin-top: 41px;
    border-radius: 1.5rem;
}

.innercarouselDiv,
.carouselimgDiv {
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
    overflow: hidden;
}

.cover-image {
    width: 98%;
    height: 100%;
    margin: auto;
    /* object-fit:contain;  */
    border-radius: 1.5rem;
}
.carousel-indicators button {
    width: 10px !important; /* Adjust size */
    height: 10px !important;
    border-radius: 50%; /* Makes the indicators circular */
    background-color: rgba(255, 255, 255, 0.5); /* Initial color */
    border: none;
    transition: background-color 0.3s ease; /* Smooth color change */
  }
  
  .carousel-indicators .active {
    background-color: rgba(255, 255, 255, 0.9); /* Active indicator color */
  }
  
  .carousel-indicators button:hover {
    background-color: rgba(255, 255, 255, 0.7); /* Hover color */
  }

@media screen and (max-width:865px) {
    .carouselDiv {
        width: 90%;
        height: 250px;
        margin: auto !important;
        margin-top: 41px;
        border-radius: 1.5rem;
    }
}

@media screen and (max-width: 630px) {
    .carouselDiv {
        width: 100%;
        height: 123px;
        margin: 0rem;
        margin-top: 17px !important;
        border-radius: 1.5rem;
    }

    .innercarouselDiv,.carouselimgDiv {
        width: 98% !important;
        height: 100%;
        border-radius: 1.5rem;
        margin-left: 0.4rem;
        overflow: hidden;
    }
    .carousel-indicators {
        position: absolute !important;
        top: 78%
      }
    .carousel-indicators button {
        width: 7px !important; /* Adjust size */
        height: 7px !important;
        border-radius: 50%; /* Makes the indicators circular */
        background-color: rgba(255, 255, 255, 0.5); /* Initial color */
        border: none;
        transition: background-color 0.3s ease; /* Smooth color change */
      }
}