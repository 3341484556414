.fullscreen-login {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  }
  
  .login-containermodal {
    width: 90%;
    max-width: 450px; /* You can set a max width for larger screens */
    border-radius: 20px;
    background-color: white;
    padding: 2% !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add some shadow for depth */
    text-align: center !important;
  }
  
  .bookhead {
    display: inline-block;
    margin: 0;
    font-size: 21px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    
  }
  
  .your-fs {
    display: inline-block;
    margin: 0;
    font-size: 21px;
    font-family: "Poppins", sans-serif;
    color: rgba(44, 156, 240, 1);
  }
  
  .login {
    text-align: start;
    width: 107px;
    height: 60px;
    font-size: 40px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-bottom: 18px;
    margin-top: 25px;
  }
  
  .closebtnX {
    position: relative;
    top: -40px;
    right: -84%;
    cursor: pointer;
    font-size: x-large;
  }
  
  .closebtn:hover {
    border: 2px solid gray;
    background-color: navy !important;
    color: white;
  }
  
  .div-num {
    justify-content: start;
  }
  
  .enter-number {
    font-family: "Poppins", sans-serif;
    display: flex;
    justify-content: start;
    height: 24px;
    font-weight: 500;
    font-size: 16px;
  }
  
  .mobileinput {
    height: 57px;
    border: solid 1.7px rgba(44, 156, 240, 1);
    border-radius: 9px;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }
  
  .getotpbtn {
    width: 100%;
    height: 45px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
  }
  
  .resend-otp {
    display: flex;
    justify-content: start;
    margin-top: 20px;
  }
  
  .resend-text {
    font-size: 13px;
    font-family: "Poppins", sans-serif;
  }
  
  .isResendDisabled {
    color: rgb(122, 186, 235);
  }
  
  .isResendEnabled {
    color: rgb(6, 132, 228);
  }
  
  .countdown {
    margin-left: 0.3rem;
    font-size: small;
  }
  @media screen and (max-width: 1139px) {
    .closebtnX {
      position: relative;
      top: -2rem;
      right: -83%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 999px) {
    .closebtnX {
      position: relative;
      top: -2rem;
      right: -82.5%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 980px) {
    .login-containermodal {
      width: 90%;
      max-width: 450px; /* You can set a max width for larger screens */
      border-radius: 20px;
      background-color: white;
      padding: 3% !important;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add some shadow for depth */
      text-align: center !important;
    }
    .login-container {
      width: 90%;
      max-width: 90%; /* Adjust width for smaller screens */
    }
    .closebtnX {
      position: relative;
      top: -2.5rem;
      right: -86%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 950px) {
    .closebtnX {
      position: relative;
      top: -2.7rem;
      right: -85.5%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 914px) {
    .closebtnX {
      position: relative;
      top: -2.7rem;
      right: -84.5%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 835px) {
    .closebtnX {
      position: relative;
      top: -2.5rem;
      right: -83.5%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 768px) {
    .closebtnX {
      position: relative;
      top: -2.2rem;
      right: -83.5%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 680px) {
    .closebtnX {
      position: relative;
      top: -2.2rem;
      right: -82%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 630px) {
    .closebtnX {
      position: relative;
      top: -2rem;
      right: -82.5%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 580px) {
    .closebtnX {
      position: relative;
      top: -2rem;
      right: -80.5%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 458px) {
    .closebtnX {
      position: relative;
      top: -1.5rem;
      right: -80.5%;
      cursor: pointer;
      font-size: x-large;
    }
  }
  @media screen and (max-width: 414px) {
    .login-containermodal {
      width:90%;
      max-width: 450px; /* You can set a max width for larger screens */
      border-radius: 20px;
      background-color: white;
      padding: 5% !important;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add some shadow for depth */
      text-align: center !important;
    }
    .closebtnX {
      position: relative;
      top: -2rem;
      right: -90.2%;
      cursor: pointer;
      font-size: x-large;
    }
  }


  